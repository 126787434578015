import {Injectable} from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";

// Configuração da API do WP
import { ConfigurationWpApi } from "../../../app.wp-api-config";


@Injectable({
    providedIn: "root"
})

export class SearchService {

    private actionUrl: string;
    private wpEndPoint: string;

    constructor(private http: HttpClient, private configWpApi: ConfigurationWpApi) {
        this.wpEndPoint = 'custom_endpoint/get_properties_by_search_box';
        this.actionUrl = configWpApi.wpApiUrl + this.wpEndPoint;
    }

    public generalSearch<T>(searchArgs: Array<any>): Observable<T> {
        let args = '';

        Object.keys(searchArgs).forEach((key, index) => {
            args += index == 0 ? '?' : '&';
            args += key + '=' + searchArgs[key];
        });

        return this.http.get<T>(this.actionUrl + args);
    }

    public neighborhoodSearch<T>(ids: Array<Number>, searchType: string = null): Observable<T> {

        let args = '?searchLocation=' + ids.join(',');
        if(searchType)
            args += '&searchType=' + searchType;

        return this.http.get<T>(this.actionUrl + args);
    }

    // busca um imóvel pelo ID
    public findOneById<T>(id: number): Observable<T> {
        return this.http.get<T>(this.actionUrl + id);
    }

}

@Injectable({
    providedIn: "root"
})
export class CustomInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.headers.has('Content-Type')) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });


        return next.handle(req);
    }
}