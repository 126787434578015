import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";

import { SearchListView } from "./views";

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: "",
                component: SearchListView
            }
        ])
    ],
    exports: [ RouterModule ]
})
export class SearchRoutingModule {}