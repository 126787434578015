import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ContactFormService } from "../../services";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: 'contact-view',
    templateUrl: './contact.view.html'
})
export class ContactView {

    //#region Declarações de variáveis
    // flags
    public isSubmitted: boolean;
    public isSubmitting: boolean;
    public error: string;

    // serviços
    private readonly activatedRoute: ActivatedRoute;
    private readonly contactFormService: ContactFormService;

    // formulário
    public contactForm: FormGroup;

    // preset message
    public message: string;
    //#endregion


    constructor(activatedRoute: ActivatedRoute, contactFormService: ContactFormService, titleService: Title) {
        this.activatedRoute = activatedRoute;
        this.contactFormService = contactFormService;

        this.error = null;
        this.isSubmitted = false;
        this.isSubmitting = false;
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((queryParams: Array<any>) => {

            this.message = queryParams['message'] ? queryParams['message'] : '';

        });

        this.createContactForm();
    }

    public performSubmitForm(): void {

        this.error = null;
        this.isSubmitting = true;
        let formData: Object = this.contactForm.getRawValue();

        this.contactFormService.submit(formData).subscribe(
            () => {
                this.isSubmitting = false;
                this.isSubmitted = true;
            },
            (error: HttpErrorResponse) => {
                this.error = error.message;
                this.isSubmitting = false;
                this.isSubmitted = true;
            },
        );
    }
    //#endregion

    //#region Private Methods
    /**
     * Criação de formulários
     * */
    private createContactForm(): void {

        this.contactForm = new FormGroup({
            name: new FormControl('', [
                Validators.required
            ]),
            email: new FormControl('', [
                Validators.required
            ]),
            phone: new FormControl('', [
                Validators.required
            ]),
            message: new FormControl(this.message, [
                Validators.required
            ])
        });

    }
    //#endregion
}