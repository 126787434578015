import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PartySaloonReservationFormService } from "../../services";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: 'party-saloon-reservation-view',
    templateUrl: './party-saloon-reservation.view.html'
})
export class PartySaloonReservationView implements OnInit {

    //#region Public Attributes
    /**
     * Flag que indica se está em processo de submissão do formulário
     * @default false
     */
    public isSubmitting: boolean;
    public isSubmitted: boolean;
    /**
     * Erro ocorrido na submissão do formulário.
     * @default null
     */
    public error: string;
    /**
     * Formulário de reserva de aréa de festa.
     */
    public saloonReservationForm: FormGroup;
    //#endregion

    //#region Private Attributes
    /**
     * @injected
     */
    private readonly activatedRoute: ActivatedRoute;
    /**
     * @injected
     */
    private readonly partySaloonReservationFormService: PartySaloonReservationFormService;
    //#endregion

    //#region Constructor
    constructor(activatedRoute: ActivatedRoute,
                partySaloonReservationFormService: PartySaloonReservationFormService) {

        // Injetados
        this.activatedRoute = activatedRoute;
        this.partySaloonReservationFormService = partySaloonReservationFormService;

        // Públicos
        this.isSubmitting = false;
        this.isSubmitted = false;
        this.error = null;
    }
    //#endregion

    //#region Lifecycle Hooks
    /**
     * @inheritDoc
     */
    public ngOnInit(): void {
        this.createSaloonReservationForm();
    }
    //#endregion

    //#region Public Methods
    public performSubmitForm(): void {

        this.error = null;
        this.isSubmitting = true;
        const formData: Object = this.saloonReservationForm.getRawValue();
        this.partySaloonReservationFormService.submit(formData).subscribe(
            () => {
                this.isSubmitting = false;
                this.isSubmitted = true;
            },
            (error: HttpErrorResponse) => {
                this.error = error.message;
                this.isSubmitting = false;
                this.isSubmitted = true;
            },
        );
    }
    //#endregion

    //#region Private Methods
    /**
     * Criação de formulários
     * */
    private createSaloonReservationForm(): void {

        this.saloonReservationForm = new FormGroup({
            rentalName: new FormControl('', [
                Validators.required
            ]),
            rentalEmail: new FormControl('', [
                Validators.required
            ]),
            rentalCellphone: new FormControl('', [
                Validators.required
            ]),
            rentalDocument: new FormControl('', [
                Validators.required
            ]),
            propertyApartment: new FormControl('', [
                Validators.required
            ]),
            propertyBuilding: new FormControl('', [
                Validators.required
            ]),
            propertyAddress: new FormControl('', [
                Validators.required
            ]),
            rentalDate: new FormControl('', [
                Validators.required
            ]),
            // rentalTurn: new FormControl('', [
            //     Validators.required
            // ]),
            agree: new FormControl(false, [
                Validators.pattern('true')
            ])
        });
    }
    //#endregion
}