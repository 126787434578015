import { Component, Input } from "@angular/core";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NeighborhoodService } from "../../../shared/services";

@Component({
    selector: 'app-neighborhood-list',
    templateUrl: './neighborhood-list.view.html'
})

export class NeighborhoodListView {

    public isLoading: boolean;

    @Input()
    public neighborhoodIds: Array<number>;

    @Input()
    public exibition: string;

    private readonly EXIBITION_IMAGE_ONLY = 'image_only';

    public neighborhoodList: Array<any>;

    private readonly neighborhoodService: NeighborhoodService;

    neighborhoodCustomOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1.15
            },
            400: {
                items: 2.2
            },
            740: {
                items: 3
            },
            940: {
                items: 3
            }
        },
        nav: true
    }


    constructor(neighborhoodService: NeighborhoodService) {
        this.neighborhoodService = neighborhoodService;
        this.exibition = this.exibition != null ? this.exibition : this.EXIBITION_IMAGE_ONLY;
    }


    public ngOnInit(): void {
        this.neighborhoodIds = typeof this.neighborhoodIds !== "undefined" ? this.neighborhoodIds : [];
        this.isLoading = true;

        this.neighborhoodService.getNeighborhoods<any[]>(this.neighborhoodIds)
            .subscribe((data: any[]) => {
                    this.neighborhoodList = this.treatsToShow(data);
                    this.isLoading = false;
                },
                error => () => {
                    this.isLoading = false;
                });
    }

    private treatsToShow(neighborhoodList: Array<any>): Array<any> {

        let newNeighborhoodList = [];

        neighborhoodList.forEach((neighborhood: any) => {

            neighborhood.url = neighborhood.ID + '-' + neighborhood.post_name;

            if(this.exibition == this.EXIBITION_IMAGE_ONLY) {
                if(neighborhood.images.length)
                    newNeighborhoodList.push(neighborhood);
            } else {
                newNeighborhoodList.push(neighborhood);
            }
        });

        return newNeighborhoodList;
    }
}
