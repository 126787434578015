import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeView } from './app-views';


@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: "",
        component: HomeView
      },
      //--- Módulos
      // Teste
      {
        path: "teste",
        loadChildren: () => import("./teste/private/teste.module").then(m => m.TesteModule)
      },
      // Imóvel
      {
        path: "imovel",
        loadChildren: () => import("./property/private/property.module").then(m => m.PropertyModule)
      },
      // Imóvel (link indexado)
      {
        path: "detalhes-imovel.php",
        loadChildren: ()  => import("./property/private/property.module").then(m => m.PropertyModule)
      },
      // bairro
      {
        path: "bairro",
        loadChildren: () => import("./neighborhood/private/neighborhood.module").then(m => m.NeighborhoodModule)
      },
      // busca
      {
        path: "busca",
        loadChildren: () => import("./search/private/search.module").then(m => m.SearchModule)
      },
      // fichas cadastrais
      {
        path: "formularios-de-cadastro",
        loadChildren: () => import("./registration-form/private/registration-form.module").then(m => m.RegistrationFormModule)
      },
      // páginas
      {
        path: "pagina",
        loadChildren: () => import("./page/private/page.module").then(m => m.PageModule)
      },
      // time
      {
        path: "equipe",
        loadChildren: () => import("./team/private/team.module").then(m => m.TeamModule)
      },
      // contato
      {
        path: "contato",
        loadChildren: () => import("./contact/private/contact.module").then(m => m.ContactModule)
      }
    ], {scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
