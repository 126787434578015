import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PersonRegistrationFormService } from "../../services";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: 'owner-property-view',
    templateUrl: './owner-property.view.html'
})
export class OwnerPropertyView {

    //#region Public Attributes
    /**
     * Flag que indica se está em processo de submissão do formulário
     * @default false
     */
    public isSubmitting: boolean;
    public isSubmitted: boolean;
    /**
     * Erro ocorrido na submissão do formulário.
     * @default null
     */
    public error: string;
    /**
     * Formulário de cadastro de proprietário.
     */
    public ownerForm: FormGroup;
    //#endregion

    //#region Private Attributes
    /**
     * @injected
     */
    private readonly activatedRoute: ActivatedRoute;
    /**
     * @injected
     */
    private readonly personRegistrationFormService: PersonRegistrationFormService;
    //#endregion

    //#region Constructor
    constructor(activatedRoute: ActivatedRoute,
                personRegistrationFormService: PersonRegistrationFormService) {

        // Injetados
        this.activatedRoute = activatedRoute;
        this.personRegistrationFormService = personRegistrationFormService;

        // Públicos
        this.isSubmitting = false;
        this.isSubmitted = false;
        this.error = null;
    }
    //#endregion

    //#region Lifecycle Hooks
    /**
     * @inheritDoc
     */
    public ngOnInit(): void {
        this.createOwnerForm();
    }
    //#endregion

    //#region Public Methods
    public processFile(imageInput: any, formControl: FormControl): void {

        const files: Array<File> = imageInput.files;
        formControl.setValue(null);

        for (let file of files) {

            const reader: FileReader = new FileReader();

            reader.addEventListener("load", (event: any) => {

                let formControlValue = formControl.value != null ? formControl.value : [];

                formControlValue.push(event.target.result);
                formControl.setValue(formControlValue);
            });
            reader.readAsDataURL(file);
        }
    }

    public performSubmitForm(): void {

        this.error = null;
        this.isSubmitting = true;
        let formData: Object = this.ownerForm.getRawValue();
        formData = {...formData, isOwner: true};
        formData = {...formData};

        this.personRegistrationFormService.submit(formData).subscribe(
            () => {
                this.isSubmitting = false;
                this.isSubmitted = true;
            },
            (error: HttpErrorResponse) => {
                this.error = error.message;
                this.isSubmitting = false;
                this.isSubmitted = true;
            },
        );
    }
    //#endregion

    //#region Private Methods
    /**
     * Criação de formulários
     * */
    private createOwnerForm(): void {

        this.ownerForm = new FormGroup({
            personName: new FormControl('', [
                Validators.required
            ]),
            personEmail: new FormControl('', [
                Validators.required
            ]),
            personCellphone: new FormControl('', [
                Validators.required
            ]),
            personDocument: new FormControl('', [
                Validators.required
            ]),
            personIdentity: new FormControl('', [
                Validators.required
            ]),
            personNacionatily: new FormControl('', [
                Validators.required
            ]),
            personNaturalFrom: new FormControl('', [
                Validators.required
            ]),
            personFatherName: new FormControl('', [
                Validators.required
            ]),
            personMotherName: new FormControl('', [
                Validators.required
            ]),
            personHasPartner: new FormControl(false),

            // dados de endereço
            personCep: new FormControl('', [
                Validators.required
            ]),
            personUf: new FormControl('', [
                Validators.required
            ]),
            personCity: new FormControl('', [
                Validators.required
            ]),
            personNeighborhood: new FormControl('', [
                Validators.required
            ]),
            personAddress: new FormControl('', [
                Validators.required
            ]),
            personNumber: new FormControl('', [
                Validators.required
            ]),
            personCompliment: new FormControl(''),

            // dados do conjuge
            partnerName: new FormControl('', [
                Validators.required
            ]),
            partnerJobTitle: new FormControl(''),
            partnerJobEnterprise: new FormControl(''),
            partnerDocument: new FormControl('', [
                Validators.required
            ]),
            partnerIdentity: new FormControl('', [
                Validators.required
            ]),
            partnerNacionatily: new FormControl('', [
                Validators.required
            ]),
            partnerNaturalFrom: new FormControl('', [
                Validators.required
            ]),
            partnerFatherName: new FormControl('', [
                Validators.required
            ]),
            partnerMotherName: new FormControl('', [
                Validators.required
            ]),

            // dados bancarios
            bankAgency: new FormControl('', [
                Validators.required
            ]),
            bankAccount: new FormControl('', [
                Validators.required
            ]),
            bankName: new FormControl('', [
                Validators.required
            ]),

            // trabalho
            personJobEnterprise: new FormControl('', [
                Validators.required
            ]),
            personJobTitle: new FormControl('', [
                Validators.required
            ]),
            personJobPhone: new FormControl('', [
                Validators.required
            ]),

            // documentos
            personDocumentFile: new FormControl('', [
                Validators.required
            ]),
            personResidenceFile: new FormControl('', [
                Validators.required
            ]),
            personIncomeProof: new FormControl('', [
                Validators.required
            ]),

            // documentos conjuge
            partnerDocumentFile: new FormControl('', [
                Validators.required
            ]),

            agree: new FormControl(false, [
                Validators.pattern('true')
            ])
        });

        this.onHasPartnerChange(false);

        this.ownerForm.get("personHasPartner").valueChanges.subscribe((value: boolean) => {

            this.onHasPartnerChange(value);
        })
    }

    /**
     * Event listener para alterações no checkbox de "Tem cônjuge?"
     * @param value Novo valor do campo.
     */
    private onHasPartnerChange(value: boolean): void {

        if (value) {

            this.ownerForm.get("partnerName").enable();
            this.ownerForm.get("partnerJobTitle").enable();
            this.ownerForm.get("partnerJobEnterprise").enable();
            this.ownerForm.get("partnerDocument").enable();
            this.ownerForm.get("partnerIdentity").enable();
            this.ownerForm.get("partnerNacionatily").enable();
            this.ownerForm.get("partnerNaturalFrom").enable();
            this.ownerForm.get("partnerFatherName").enable();
            this.ownerForm.get("partnerMotherName").enable();
            this.ownerForm.get("partnerDocumentFile").enable();
        } else {

            this.ownerForm.get("partnerName").disable();
            this.ownerForm.get("partnerJobTitle").disable();
            this.ownerForm.get("partnerJobEnterprise").disable();
            this.ownerForm.get("partnerDocument").disable();
            this.ownerForm.get("partnerIdentity").disable();
            this.ownerForm.get("partnerNacionatily").disable();
            this.ownerForm.get("partnerNaturalFrom").disable();
            this.ownerForm.get("partnerFatherName").disable();
            this.ownerForm.get("partnerMotherName").disable();
            this.ownerForm.get("partnerDocumentFile").disable();
        }
    }
    //#endregion
}