import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";

import { PropertyView } from "./views";
import { RedirectGuardService } from "./guards";

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: "",
                canActivate: [RedirectGuardService],
                component: PropertyView
            },
            {
                path: ":rewriteUrl",
                component: PropertyView
            }
        ])
    ],
    exports: [ RouterModule ]
})
export class PropertyRoutingModule {}