import { NgModule } from '@angular/core';

import { NeighborhoodView, NeighborhoodListView, NeighborhoodListPageView } from './views';
import { NeighborhoodRoutingModule } from "./neighborhood.routing";
import { CommonModule } from '@angular/common';
import { CarouselModule } from "ngx-owl-carousel-o";
// import { PropertyModule } from "../../property/private/property.module";

@NgModule({
    imports: [
        CommonModule,
        NeighborhoodRoutingModule,
        CarouselModule,
        // PropertyModule
    ],
    declarations: [
        // Views
        NeighborhoodView,
        NeighborhoodListView,
        NeighborhoodListPageView
    ],
    exports: [
        NeighborhoodListView
    ]
})
export class NeighborhoodModule {
}