import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { JuridicalRegistrationFormService } from "../../services";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: 'juridical-person-view',
    templateUrl: './juridical-person.view.html'
})
export class JuridicalPersonView {

    //#region Public Attributes
    /**
     * Flag que indica se está em processo de submissão do formulário
     * @default false
     */
    public isSubmitting: boolean;
    public isSubmitted: boolean;
    /**
     * Erro ocorrido na submissão do formulário.
     * @default null
     */
    public error: string;
    /**
     * Formulário de cadastro de pessoa juridica.
     */
    public juridicalPersonForm: FormGroup;
    //#endregion

    //#region Private Attributes
    /**
     * @injected
     */
    private readonly activatedRoute: ActivatedRoute;
    /**
     * @injected
     */
    private readonly juridicalRegistrationFormService: JuridicalRegistrationFormService;
    //#endregion

    //#region Constructor
    constructor(activatedRoute: ActivatedRoute,
                juridicalRegistrationFormService: JuridicalRegistrationFormService) {

        // Injetados
        this.activatedRoute = activatedRoute;
        this.juridicalRegistrationFormService = juridicalRegistrationFormService;

        // Públicos
        this.isSubmitting = false;
        this.isSubmitted = false;
        this.error = null;
    }
    //#endregion

    //#region Lifecycle Hooks
    /**
     * @inheritDoc
     */
    public ngOnInit(): void {
        this.createJuridicalPersonForm();
    }
    //#endregion

    //#region Public Methods
    public processFile(imageInput: any, formControl: FormControl): void {

        const files: Array<File> = imageInput.files;
        formControl.setValue(null);

        for (let file of files) {

            const reader: FileReader = new FileReader();

            reader.addEventListener("load", (event: any) => {

                let formControlValue = formControl.value != null ? formControl.value : [];

                formControlValue.push(event.target.result);
                formControl.setValue(formControlValue);
            });
            reader.readAsDataURL(file);
        }
    }

    public addCommercialReference() {

        const formArray: FormArray = this.juridicalPersonForm.get('commercialReferences') as FormArray;

        formArray.push(new FormGroup({
            name: new FormControl('', Validators.required),
            phone: new FormControl('', Validators.required)
        }))
    }

    public addPartner() {

        const formArray: FormArray = this.juridicalPersonForm.get('partnerDocuments') as FormArray;

        formArray.push(new FormGroup({
            partnerDocumentFile: new FormControl('', Validators.required),
            partnerResidenceFile: new FormControl('', Validators.required)
        }))
    }

    public addBankReference() {

        const formArray: FormArray = this.juridicalPersonForm.get('bankReferences') as FormArray;

        formArray.push(new FormGroup({
            bankAgency: new FormControl('', [Validators.required]),
            bankAccount: new FormControl('', [Validators.required]),
            bankName: new FormControl('', [Validators.required]),
            bankManager: new FormControl('', [Validators.required]),
        }))
    }

    public performSubmitForm(): void {

        this.error = null;
        this.isSubmitting = true;
        let formData: Object = this.juridicalPersonForm.getRawValue();

        this.juridicalRegistrationFormService.submit(formData).subscribe(
            () => {
                this.isSubmitting = false;
                this.isSubmitted = true;
            },
            (error: HttpErrorResponse) => {
                this.error = error.message;
                this.isSubmitting = false;
                this.isSubmitted = true;
            },
        );
    }
    //#endregion

    //#region Private Methods
    /**
     * Criação de formulários
     * */
    private createJuridicalPersonForm(): void {

        this.juridicalPersonForm = new FormGroup({
            personName: new FormControl('', [
                Validators.required
            ]),
            personEmail: new FormControl('', [
                Validators.required
            ]),
            personCellphone: new FormControl('', [
                Validators.required
            ]),
            personDocument: new FormControl('', [
                Validators.required
            ]),
            personIE: new FormControl(''),
            personBusinessType: new FormControl('', [
                Validators.required
            ]),
            personEmployees: new FormControl('', [
                Validators.required
            ]),

            // dados de endereço
            personCep: new FormControl('', [
                Validators.required
            ]),
            personUf: new FormControl('', [
                Validators.required
            ]),
            personCity: new FormControl('', [
                Validators.required
            ]),
            personNeighborhood: new FormControl('', [
                Validators.required
            ]),
            personAddress: new FormControl('', [
                Validators.required
            ]),
            personNumber: new FormControl('', [
                Validators.required
            ]),
            personCompliment: new FormControl(''),

            commercialReferences: new FormArray([
                new FormGroup({
                    name: new FormControl('', Validators.required),
                    phone: new FormControl('', Validators.required)
                })
            ]),

            bankReferences: new FormArray([
                new FormGroup({
                    bankAgency: new FormControl('', [Validators.required]),
                    bankAccount: new FormControl('', [Validators.required]),
                    bankName: new FormControl('', [Validators.required]),
                    bankManager: new FormControl('', [Validators.required]),
                })
            ]),

            // documentos
            personDocumentFile: new FormControl('', [
                Validators.required
            ]),
            personSocialContractFile: new FormControl('', [
                Validators.required
            ]),
            personResidenceFile: new FormControl('', [
                Validators.required
            ]),
            personIncomeProof: new FormControl('', [
                Validators.required
            ]),

            // documentos sócios
            hasPartner: new FormControl(false),

            partnerDocuments: new FormArray([
                new FormGroup({
                    partnerDocumentFile: new FormControl('', [Validators.required]),
                    partnerResidenceFile: new FormControl('', [Validators.required])
                })
            ]),

            agree: new FormControl(false, [
                Validators.pattern('true')
            ])
        });

        this.onHasPartnerChange(false);

        this.juridicalPersonForm.get("hasPartner").valueChanges.subscribe((value: boolean) => {

            this.onHasPartnerChange(value);
        })
    }

    /**
     * Event listener para alterações no checkbox de "Tem sócio?"
     * @param value Novo valor do campo.
     */
    private onHasPartnerChange(value: boolean): void {

        if (value) {

            this.juridicalPersonForm.get("partnerDocuments").enable();
        } else {

            this.juridicalPersonForm.get("partnerDocuments").disable();
        }
    }
    //#endregion
}