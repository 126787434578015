import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigurationWpApi } from "../../../app.wp-api-config";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})

export class SearchboxService {

    private actionUrl: string;
    private wpEndpoint: string;

    constructor(private http: HttpClient, private configWpApi: ConfigurationWpApi) {}

    // busca os tipos de imóveis disponíveis
    public gerPropertyTypes<T>(): Observable<T> {

        this.wpEndpoint = 'wp/v2/property_type/?per_page=50';
        this.actionUrl = this.configWpApi.wpApiUrl + this.wpEndpoint;

        return this.http.get<T>(this.actionUrl)
    }

    // as localidades disponíveis
    public gerPropertyLocations<T>(): Observable<T> {

        this.wpEndpoint = 'wp/v2/neighborhood/?per_page=100&show_on_search=1';
        this.actionUrl = this.configWpApi.wpApiUrl + this.wpEndpoint;

        return this.http.get<T>(this.actionUrl)
    }

}
