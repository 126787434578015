import {Component, OnInit, ViewChild, Input, Inject, PLATFORM_ID, NgZone} from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { NearbyPlacesService } from "./services/nearby-places.service";
import { isPlatformBrowser } from "@angular/common";

@Component({
    selector: 'app-nearby-places-map',
    templateUrl: './nearby-places.component.html'
})
export class NearbyPlacesComponent implements OnInit {

    @ViewChild('nearbyPlacesMap', {static: false})
    nearbyPlacesMap: any;

    @Input()
    latitude: number = 42.345573;

    @Input()
    longitude: number = -71.098326;

    @Input()
    zoom: number = 16;

    @Input()
    scrollwheel: boolean = false;

    private map: any;

    public loadingLocations: boolean;

    public nearbyPlacesReached: any;

    private locationTypes = [
        'park',
        'school',
        'university',
        'hospital',
        'church',
        'pharmacy',
        'restaurant',
        'bus_station',
        'supermarket',
        'gas_station'
    ];
    private typeNamed = {
        'park': ['Parques', 'o'],
        'school': ['Instituições de ensino', 'a'],
        'university': ['Universidades', 'a'],
        'hospital': ['Hospitais', 'o'],
        'church': ['Igrejas', 'a'],
        'pharmacy': ['Farmácias', 'a'],
        'restaurant': ['Restaurantes', 'o'],
        'bus_station': ['Rodoviária', 'a'],
        'supermarket': ['Supermercados', 'o'],
        'gas_station': ['Postos de combustível', 'o']
    };


    private readonly nearbyPlacesServices: NearbyPlacesService;

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private ngZone: NgZone, private mapsAPILoader: MapsAPILoader, nearbyPlacesService: NearbyPlacesService) {
        this.nearbyPlacesServices = nearbyPlacesService;
        this.loadingLocations = true;
        this.nearbyPlacesReached = null;
    }

    async ngOnInit() {
        if(isPlatformBrowser(this.platformId)){

            this.loadingLocations = true;
            let response = await this.nearbyPlacesServices.findWordpressPlaces(this.latitude, this.longitude).toPromise();

            this.mapsAPILoader.load().then(() => {

                let center = { lat: this.latitude, lng: this.longitude };
                this.map = new window['google'].maps.Map(this.nearbyPlacesMap.nativeElement, { center: center, zoom: this.zoom, scrollwheel: this.scrollwheel });
                var marker = new window['google'].maps.Marker({
                    position: center,
                    map: this.map,
                    title: 'Hello World!'
                });
                if (response) {
                    this.loadingLocations = false;
                    this.nearbyPlacesReached = JSON.parse(response);
                }
                else {

                    this.nearbyPlacesServices
                        .searchNearbyPlaces(this.latitude, this.longitude, this.map)
                        .then((data: any[]) => {

                            this.nearbyPlacesReached = data;
                            this.nearbyPlacesServices.setWordpressPlaces(this.latitude, this.longitude, data).toPromise()
                                .then((response) => {
                                    console.log("response", response);
                                })
                                .catch((error: Error) => {
                                    console.log("error", error);
                                });
                            this.loadingLocations = false;
                        });
                }
            })
        }
    }
    
    public getMap(): any {
        return this.map;
    }

    public async categoryzePlaces(data: Array<any>): Promise<Array<any>> {
        let categorized = [];

        for (let type of this.locationTypes) {
            categorized[type] = [];
            for (let items of data) {
                if(items !== undefined) {
                    for (let item of items) {
                        if (item.types.indexOf(type) !== -1) {
                            categorized[type].push(item);
                        }
                    }
                }
            }
        }
        return categorized;
    }

    public getMainType(items): string {
        let type = '';

        if (items == undefined)
            return 'none';

        for (let category of this.locationTypes) {
            for (let item of items) {
                if (item.types.indexOf(category) !== -1)
                    return category;
            }
        }
        return items[0].types[0];
    }


    public countTypeItems(items): number {
        let type = '';

        if (items == undefined)
            return 0;

        for (let category of this.locationTypes) {
            for (let item of items) {
                if (item.types.indexOf(category) !== -1)
                    return items.length;
            }
        }
        return items.length;
    }

}
