import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigurationWpApi } from "../../../app.wp-api-config";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NearbyPlacesService {
  /**
   * Url de ação para requisição.
   */
  private actionUrl: string;
  /**
   * Endpoint da aplicação wordpress.
   */
  protected wpEndpoint: string;

  private placesReached: any;

  private googleMapsService: any;

  private locationTypes = [
    "park",
    // 'university',
    "school",
    "hospital",
    "church",
    "pharmacy",
    "restaurant",
    "bus_station",
    "supermarket",
    "gas_station",
  ];

  constructor(private http: HttpClient, private configWpApi: ConfigurationWpApi) {
    this.placesReached = [];

    this.wpEndpoint = "custom_endpoint/maps/nearby_places";
    this.actionUrl = configWpApi.wpApiUrl + this.wpEndpoint;
  }

  public async searchNearbyPlaces(lat, lon, map): Promise<any> {
    const googleMapsService = new window["google"].maps.places.PlacesService(map);

    var pyrmont = new window["google"].maps.LatLng(lat, lon);

    let requestPool = [];

    for (let type of this.locationTypes) {
      let request = {
        location: pyrmont,
        radius: "500",
        type: type,
      };
      requestPool.push(this.searchNearbyPlacesGoogle(googleMapsService, request));
    }

    return await Promise.all(requestPool);
  }

  /**
   * ESSA MERDA É ASSINCRONA MAS NÃO FUNCIONA DE FORMA ASSINCRONA
   *
   * A API do Google Maps é burra. Não adianta tentar,
   * se você conseguir resolver e retornar de forma correta
   * pro front te pago um X Tudo e uma coca lata
   *
   * TCHARLES RESOLVEU SAPORRA COM A FUNÇÂO DE BAIXO.
   * DIA 04/05/2020. ESPERANDO MEU X TUDO COM A COCA LATA.
   * */
  public async nearbySearchCustom(request) {
    return new Promise((resolve, reject) => {
      this.googleMapsService.nearbySearch(request, async (results, status) => {
        if (status == window["google"].maps.places.PlacesServiceStatus.OK) {
          resolve(results);
        } else {
          resolve();
        }
      });
    });
  }
  public searchNearbyPlacesGoogle(googleMapsService: any, request: any) {
    return new Promise(function (resolve, reject) {
      googleMapsService.nearbySearch(request, function (results, status) {
        if (status != window["google"].maps.places.PlacesServiceStatus.OK) {
          // reject(new Error(status));
          resolve();
        } else {
          resolve(results);
        }
      });
    });
  }

  public findWordpressPlaces(latitude: number, longitude: number): Observable<any> {
    let endpoint =
      this.actionUrl + "?latitude=" + latitude + "&longitude=" + longitude + "&radius=500";
    return this.http.get(endpoint);
  }

  public setWordpressPlaces(latitude: number, longitude: number, data: Object) {
    let request = { data: data, latitude: latitude, longitude: longitude, radius: "500" };
    return this.http.post(this.actionUrl, request);
  }
}
