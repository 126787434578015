import { NgModule } from '@angular/core';

import { SearchBoxComponent, SearchListView } from './views';
import { CommonModule } from '@angular/common';
import { SearchRoutingModule } from "./search.routing";
import { ReactiveFormsModule } from "@angular/forms";
import { PropertyModule } from "../../property/private/property.module";

// import { AgmCoreModule } from "@agm/core";

@NgModule({
    imports: [
        CommonModule,
        SearchRoutingModule,
        ReactiveFormsModule,
        PropertyModule,
    ],
    declarations: [
        // Views
        SearchListView,
        SearchBoxComponent
    ],
    exports: [
        SearchBoxComponent
    ]
})
export class SearchModule {
}