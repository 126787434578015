import { NgModule } from '@angular/core';

import { PropertyListView, PropertyView, RecommendedPropertiesComponent } from './views';
import { PropertyRoutingModule } from "./property.routing";
import { CommonModule } from '@angular/common';
import { CarouselModule } from "ngx-owl-carousel-o";
import { ReactiveFormsModule } from "@angular/forms";
import { NeighborhoodModule } from "../../neighborhood/private/neighborhood.module";
import { AgmCoreModule } from "@agm/core";
import { RegistrationFormModule } from "../../registration-form/private/registration-form.module";
import { AngularStickyThingsModule } from "@w11k/angular-sticky-things";
import { NearbyPlacesComponent, StreetviewComponent } from "../../app-components";

@NgModule({
	imports: [
		CommonModule,
		PropertyRoutingModule,
		CarouselModule,
		ReactiveFormsModule,
		NeighborhoodModule,
		AgmCoreModule,
		RegistrationFormModule,
        AngularStickyThingsModule
	],
    exports: [
        PropertyListView,
        RecommendedPropertiesComponent
    ],
    declarations: [
        // Views
        PropertyView,
        PropertyListView,
        RecommendedPropertiesComponent,
        StreetviewComponent,
        NearbyPlacesComponent
    ]
})
export class PropertyModule {
}