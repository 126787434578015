import { Component } from "@angular/core";
import { NeighborhoodService } from "../../../shared/services";

@Component({
    selector: 'neighborhood-list-page-view',
    templateUrl: './neighborhood-list-page.view.html'
})
export class NeighborhoodListPageView {

    public isLoading: boolean;

    public exibition: string;

    private readonly EXIBITION_IMAGE_ONLY = 'image_only';

    public neighborhoodList: Array<any>;

    private readonly neighborhoodService: NeighborhoodService;


    constructor(neighborhoodService: NeighborhoodService) {
        this.neighborhoodService = neighborhoodService;
        this.exibition = this.EXIBITION_IMAGE_ONLY;
    }


    public ngOnInit(): void {
        this.isLoading = true;

        this.neighborhoodService.getNeighborhoods<any[]>([])
            .subscribe((data: any[]) => {
                    this.neighborhoodList = this.treatsToShow(data);
                    this.isLoading = false;
                },
                error => () => {
                    this.isLoading = false;
                });
    }

    private treatsToShow(neighborhoodList: Array<any>): Array<any> {

        let newNeighborhoodList = [];

        neighborhoodList.forEach((neighborhood: any) => {

            neighborhood.url = neighborhood.ID + '-' + neighborhood.post_name;

            if(this.exibition == this.EXIBITION_IMAGE_ONLY) {
                if(neighborhood.images.length)
                    newNeighborhoodList.push(neighborhood);
            } else {
                newNeighborhoodList.push(neighborhood);
            }
        });

        return newNeighborhoodList;
    }


}