import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";

import { NeighborhoodView, NeighborhoodListPageView } from "./views";

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: "",
                component: NeighborhoodListPageView
            },
            {
                path: ":rewriteUrl",
                component: NeighborhoodView
            }
        ])
    ],
    exports: [ RouterModule ]
})
export class NeighborhoodRoutingModule {}