import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PropertyInterestFormService } from "../../services";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: 'property-interest-view',
    templateUrl: './property-interest.view.html'
})
export class PropertyInterestView implements OnInit {

    //#region Inputs
    /**
     *
     */
    @Input()
    public id: number;
    //#endregion

    //#region Public Attributes
    /**
     * Flag que indica se está em processo de submissão do formulário
     * @default false
     */
    public isSubmitting: boolean;
    /**
     * Flag que indica se está em processo de submissão do formulário
     * @default false
     */
    public isSubmitted: boolean;
    /**
     * Erro ocorrido na submissão do formulário.
     * @default null
     */
    public error: string;
    /**
     * Formulário de interesse no imóvel.
     */
    public propertyInterestForm: FormGroup;
    //#endregion

    //#region Private Attributes
    /**
     * @injected
     */
    private readonly propertyInterestFormService: PropertyInterestFormService;
    //#endregion

    //#region Constructor
    constructor(propertyInterestFormService: PropertyInterestFormService) {

        // Injetados
        this.propertyInterestFormService = propertyInterestFormService;

        /// Públicos
        this.isSubmitting = false;
        this.isSubmitted = false;
        this.error = null;
    }
    //#endregion

    //#region Lifecycle Hooks
    /**
     * @inheritDoc
     */
    public ngOnInit(): void {
        this.createPropertyInterestForm();
    }
    //#endregion

    //#region Public Methods
    public performSubmitForm(): void {

        this.error = null;
        this.isSubmitting = true;
        let formData: Object = this.propertyInterestForm.getRawValue();
        formData = {...formData, interestedProperty: this.id};
        this.propertyInterestFormService.submit(formData).subscribe(
            () => {
                this.isSubmitting = false;
                this.isSubmitted = true;
            },
            (error: HttpErrorResponse) => {
                this.error = error.message;
                this.isSubmitting = false;
                this.isSubmitted = true;
            },
        );
    }
    //#endregion

    //#region Private Methods
    /**
     * Criação de formulários
     * */
    private createPropertyInterestForm(): void {

        this.propertyInterestForm = new FormGroup({
            name: new FormControl('', [
                Validators.required
            ]),
            email: new FormControl('', [
                Validators.required
            ]),
            phone: new FormControl('', [
                Validators.required
            ])
        });
    }
    //#endregion
}