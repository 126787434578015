import { NgModule } from '@angular/core';

import {
    PersonRegistrationFormListView,
    PartySaloonReservationView,
    AnnouncePropertyView,
    RegistrationFormListView,
    VacancyPropertyView,
    OwnerPropertyView,
    PhysicalPersonView,
    JuridicalPersonView,
    NewsletterView, PropertyInterestView, PropertyInterestDesktopView, ContactView
} from "./views";
import { CommonModule } from '@angular/common';
import { RegistrationFormRoutingModule } from './registration-form.routing';
import { ReactiveFormsModule } from "@angular/forms";
import { NgxMaskModule } from "ngx-mask";

@NgModule({
    imports: [
        CommonModule,
        RegistrationFormRoutingModule,
        ReactiveFormsModule,
        NgxMaskModule
    ],
    exports: [
        NewsletterView,
        PropertyInterestView,
        PropertyInterestDesktopView,
        ContactView
    ],
    declarations: [
        // Views
        RegistrationFormListView,
        AnnouncePropertyView,
        ContactView,
        NewsletterView,
        PropertyInterestView,
        PropertyInterestDesktopView,
        VacancyPropertyView,
        PartySaloonReservationView,
        PersonRegistrationFormListView,
        OwnerPropertyView,
        PhysicalPersonView,
        JuridicalPersonView
    ]
})
export class RegistrationFormModule {
}