import {Component, Input} from "@angular/core";
import { OwlOptions } from 'ngx-owl-carousel-o';
import {PropertyService} from "../../../shared/services";

@Component({
    selector: 'app-recommended-properties',
    templateUrl: './recommended-properties.component.html'
})

export class RecommendedPropertiesComponent {

    // flags
    private isLoading: boolean;

    // inputs de fora
    @Input()
    private searchForSomethingLikeThis: Array<any>;

    // variaveis internas
    private properties: Array<any>;

    // services
    private readonly propertyService: PropertyService;

    constructor(propertyService: PropertyService) {
        this.propertyService = propertyService;
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.propertyService.searchForSomethingLikeThis<any[]>(this.searchForSomethingLikeThis)
            .subscribe((data: any[]) => {
                    this.properties = data['mainSearch'];
                    this.isLoading = false;
                },
                error => () => {
                    this.isLoading = false;
                }
            );

    }

    recommendedPropertiesCustomOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1.15
            },
            400: {
                items: 2.2
            },
            740: {
                items: 3
            },
            940: {
                items: 4
            }
        },
        nav: false
    }
}
