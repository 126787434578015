import { Observable } from "rxjs";
import { ConfigurationWpApi } from "../../../app.wp-api-config";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class BaseFormService {

	//#region Private Attributes
	/**
	 * Url de ação para requisição.
	 */
	private actionUrl: string;
	//#endregion

	//#region Protected Attributes
	/**
	 * Endpoint da aplicação wordpress.
	 */
	protected wpEndpoint: string;
	//#endregion

	//#region Constructor
	constructor(private http: HttpClient, private configWpApi: ConfigurationWpApi, wpEndpoint: string) {

		this.wpEndpoint = wpEndpoint;
		this.actionUrl = configWpApi.wpApiUrl + this.wpEndpoint;
	}
	//#endregion

	//#region Public Methods
	/**
	 * Submeter formulário
	 * @param data Dados a serem enviados.
	 */
	public submit<T>(data: Object): Observable<T> {
		return this.http.post<T>(this.actionUrl, data);
	}
	//#endregion
}