import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigurationWpApi } from "../../../app.wp-api-config";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})

export class SlidersService {


    private actionUrl: string;
    private wpEndpoint: string;
    private wpApiOptEndpoint: string;

    private readonly MENU_TO_LOAD_MOBILE = 'main_menu';
    private readonly MENU_TO_LOAD_DESKTOP = 'desktop_main_menu';

    constructor(private http: HttpClient, private configWpApi: ConfigurationWpApi) {
        this.wpEndpoint = 'wp/v2/slider';
        this.actionUrl = configWpApi.wpApiUrl + this.wpEndpoint;
        this.wpApiOptEndpoint = configWpApi.wpApiOptUrl;
    }

    public getSliders<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrl)
    }


}
