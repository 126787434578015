import {Component, Input} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NeighborhoodService } from "../../../shared/services";
import { SearchService } from "../../../../search/shared/services";
import { PreviousRouteService } from "../../../../app-services/previous-router.service";
import {PropertyService} from "../../../../property/shared/services";
import {OwlOptions} from "ngx-owl-carousel-o";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: 'neighborhood-view',
    templateUrl: './neighborhood.view.html'
})
export class NeighborhoodView {

    // flags
    public isLoading: boolean;
    public isLoadingProperties: boolean;

    public neighborhoodId: number;
    public neighborhood: Array<any>;
    public saleProperties: Array<any>;
    public rentProperties: Array<any>;

    // serviços
    private readonly activatedRoute: ActivatedRoute;
    private readonly neighborhoodService: NeighborhoodService;
    private readonly searchService: SearchService;
    private readonly propertyService: PropertyService;
    private readonly sanitizer: DomSanitizer;


    constructor(activatedRoute: ActivatedRoute, neighborhoodService: NeighborhoodService, searchService: SearchService, propertyService: PropertyService, sanitizer: DomSanitizer) {
        this.activatedRoute = activatedRoute;
        this.neighborhoodService = neighborhoodService;
        this.searchService = searchService;
        this.propertyService = propertyService;
        this.sanitizer = sanitizer;

        this.isLoading = false;
        this.isLoadingProperties = false;
    }

    public ngOnInit(): void {
        this.activatedRoute.params.subscribe((params: {rewriteUrl?: string}) => {

            // pega o id do imóvel em questão
            let splitedParams = params.rewriteUrl.split('-');
            this.neighborhoodId = Number(splitedParams[0]);

            if(this.neighborhoodId) {
                this.isLoading = true;
                this.isLoadingProperties = true;

                this.neighborhoodService.getNeighborhood<any[]>(this.neighborhoodId)
                    .subscribe((data: any[]) => {
                            this.neighborhood = data;
                            this.neighborhood['content'].rendered = this.sanitizer.bypassSecurityTrustHtml(this.neighborhood['content'].rendered);
                            this.isLoading = false;
                        },
                        error => () => {
                            this.isLoading = false;
                        }
                    );

                // carrega os imóveis a venda do bairro
                this.searchService.neighborhoodSearch<any[]>([this.neighborhoodId], 'comprar')
                    .subscribe((data: any[]) => {
                            this.saleProperties = data['mainSearch'];
                            this.isLoadingProperties = false;
                        },
                        error => () => {
                            this.isLoadingProperties = false;
                        }
                    );

                // carrega imoveis para alugar no bairro
                this.searchService.neighborhoodSearch<any[]>([this.neighborhoodId], 'alugar')
                    .subscribe((data: any[]) => {
                            this.rentProperties = data['mainSearch'];
                            this.isLoadingProperties = false;
                        },
                        error => () => {
                            this.isLoadingProperties = false;
                        }
                    );
            } else {
                this.isLoading = false;
            }
        });

    }


    propertiesCustomOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navText: ['prev', 'next'],
        responsive: {
            0: {
                items: 1.15
            },
            400: {
                items: 1.15
            },
            740: {
                items: 3
            },
            940: {
                items: 3
            }
        },
        nav: true
    }


}