import { Component, OnDestroy, Renderer2 } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { PropertyService } from "../../../shared/services";
import { OwlOptions } from "ngx-owl-carousel-o";
import { FormControl, FormGroup } from "@angular/forms";
import { NeighborhoodService } from "../../../../neighborhood/shared/services";
import { Title } from "@angular/platform-browser";
import { GLOBAL_TITLE } from "../../../../app.module";
import { NgNavigatorShareService } from "ng-navigator-share";
import { Lightbox } from "ngx-lightbox";
declare let fbq: Function;

@Component({
  selector: "property-view",
  templateUrl: "./property.view.html",
})
export class PropertyView implements OnDestroy {
  // flags
  public isLoading: boolean;
  public isLoadingNeightborhood: boolean;
  public propertyNotFound: boolean;
  public activeView: Object;
  public isDescriptionOpen: boolean;
  public isVisitFormOpen: boolean;
  public isVisitFormSubmited: boolean;
  public isLoadingSubmitVisitForm: boolean;
  public zoom: number = 14;
  public nativeShare: boolean;

  // formulários
  public visitForm: any;

  // dados da do imóvel
  public propertyId: number;
  public property: Array<string>;
  public propertyNeighborhood: Array<string>;

  // serviços
  private readonly activatedRoute: ActivatedRoute;
  private readonly propertyService: PropertyService;
  private readonly neighborhoodService: NeighborhoodService;
  private readonly titleService: Title;
  private readonly sanitizer: DomSanitizer;
  private renderer: Renderer2;
  private readonly ngNavigatorShareService: NgNavigatorShareService;
  private readonly lightboxService: Lightbox;

  // plugins
  // carroussel de imagens
  public propertyImagesCustomOptions: OwlOptions;
  public _lightboxGallery: Array<any> = [];

  // CONSTRAINTS
  private readonly ACTIVE_VIEW_PHOTO = "photo";
  private readonly ACTIVE_VIEW_VIDEO = "video";
  private readonly ACTIVE_VIEW_STREETVIEW = "streetview";
  private readonly ACTIVE_VIEW_360 = "360";

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "sale-property");
    this.renderer.removeClass(document.body, "rent-property");
  }

  constructor(
    activatedRoute: ActivatedRoute,
    propertyService: PropertyService,
    neighborhoodService: NeighborhoodService,
    titleService: Title,
    sanitizer: DomSanitizer,
    renderer: Renderer2,
    ngNavigatorShareService: NgNavigatorShareService,
    lightboxService: Lightbox
  ) {
    this.activatedRoute = activatedRoute;
    this.propertyService = propertyService;
    this.neighborhoodService = neighborhoodService;
    this.titleService = titleService;
    this.sanitizer = sanitizer;
    this.renderer = renderer;
    this.ngNavigatorShareService = ngNavigatorShareService;

    this.isLoading = false;
    this.propertyId = null;
    this.propertyNotFound = false;
    this.isDescriptionOpen = false;
    this.isVisitFormOpen = false;
    this.isVisitFormSubmited = false;
    this.isLoadingSubmitVisitForm = false;
    this.nativeShare = this.ngNavigatorShareService.canShare();
    this.lightboxService = lightboxService;

    this.activeView = this.ACTIVE_VIEW_PHOTO;

    this.propertyImagesCustomOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      navText: ["prev", "next"],
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 1,
        },
        740: {
          items: 2,
        },
        940: {
          items: 3,
        },
      },
      nav: true,
    };
  }

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: { rewriteUrl?: string }) => {
      //
      // pega o id do imóvel em questão
      let splitedParams = params.rewriteUrl.split("-");
      this.propertyId = Number(splitedParams[0]);

      if (this.propertyId) {
        this.isLoading = true;
        this.isLoadingNeightborhood = true;

        this.propertyService.getProperty<any[]>(this.propertyId).subscribe(
          (data: any[]) => {
            this.property = this.treatsProperty(data);
            this.property["acf"].latitude = Number(
              this.property["acf"].latitude
            );
            this.property["acf"].longitude = Number(
              this.property["acf"].longitude
            );

            for (let image of this.property["acf"].photos) {
              this._lightboxGallery.push({
                src: image.sizes ? image.sizes["post-thumbnail"] : image.url,
              });
            }

            // Altera o título da página
            this.titleService.setTitle(
              GLOBAL_TITLE +
                " - " +
                this.propertyService.treatsTitle(
                  this.property["title"].rendered,
                  true
                )
            );
            if (this.property["acf"].finality == "Venda") {
              this.renderer.addClass(document.body, "sale-property");
            } else {
              this.renderer.addClass(document.body, "rent-property");
            }
            this.isLoading = false;

            // Puxa o bairro do imóvel
            this.neighborhoodService
              .findOneById<any[]>(this.property["acf"].region.ID)
              .subscribe(
                (data: any[]) => {
                  this.propertyNeighborhood = data;
                  this.isLoadingNeightborhood = false;
                },
                (error) => () => {
                  this.isLoadingNeightborhood = false;
                }
              );

            // Tracking do facebook
            fbq("track", "ViewContent", {
              content_type: "product",
              content_ids: [this.property["acf"].code],
              content_name: this.propertyService.treatsTitle(
                this.property["title"].rendered,
                true
              ),
              currency: "BRL",
              value: this.property["acf"].value,
            });
          },
          (error) => (error) => {
            this.isLoading = false;
            this.propertyNotFound = true;
          }
        );
      } else {
        this.isLoading = false;
      }
    });

    this.createVisitForm();
  }

  // criação de formulário
  private createVisitForm() {
    this.visitForm = new FormGroup({
      name: new FormControl(""),
      email: new FormControl(""),
      phone: new FormControl(""),
    });
  }

  private performSubmitVisitForm() {
    this.isLoadingSubmitVisitForm = true;
    setTimeout(() => {
      this.isVisitFormSubmited = true;
    }, 1200);
  }

  // altera o que é exibido no topo do imóvel (foto, video, streetview, etc)
  private activateView(view) {
    if (this.activeView !== view) {
      this.activeView = view;
    }
  }

  // altera a flag de abertura da descrição
  private toggleDescriptionOpen() {
    this.isDescriptionOpen = !this.isDescriptionOpen;
  }

  // altera flag de abertura do formulário de visita
  private toggleVisitFormOpen() {
    this.isVisitFormOpen = !this.isVisitFormOpen;
  }

  // formata os dados da propriedade antes de exibir
  private treatsProperty(property) {
    property.title.formated = this.propertyService.treatsTitle(
      property.title.rendered
    );

    if (property.acf.video)
      property.acf.video = this.getVideoUrlSafe(property.acf.video);

    return property;
  }

  // pega url do vídeo (putaria do angular de safe object)
  private getVideoUrlSafe(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  // faz o compartilhamento
  private performShare() {
    if (this.nativeShare) {
      this.ngNavigatorShareService
        .share({
          title: this.propertyService.treatsTitle(
            this.property["title"].rendered,
            true
          ),
          url:
            "https://www.imobiliariapanorama.com.br/imovel/" +
            this.activatedRoute.snapshot.params.rewriteUrl,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  }

  // abre o lightbox
  private openLightbox(galleryIndex) {
    this.lightboxService.open(this._lightboxGallery, galleryIndex);
  }
}
