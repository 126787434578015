// Angular Imports
import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

// Http Request Module
import { HttpClientModule } from "@angular/common/http";

// Reactive Forms
import { ReactiveFormsModule } from "@angular/forms";

// Carousel
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Lightbox
import { LightboxModule } from "ngx-lightbox";

// Google Maps
import { AgmCoreModule } from '@agm/core';

// Mascara de formulários
import { IConfig, NgxMaskModule } from "ngx-mask";

// Componentes da aplicação
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MenuBarComponent, SliderComponent } from "./app-components";
import { FooterComponent } from "./app-components";
import { NeighborhoodModule } from "./neighborhood/private/neighborhood.module";
import { SearchModule } from "./search/private/search.module";
import { PropertyModule } from "./property/private/property.module";

// Views
import { HomeView } from "./app-views";
import { CookieService } from "ngx-cookie-service";
import { RegistrationFormModule } from "./registration-form/private/registration-form.module";
import { WhatsappPipe } from './pipes/whatsapp.pipe';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
@NgModule({
    declarations: [
        // Components
        // root components
        AppComponent,
        MenuBarComponent,
        FooterComponent,
		SliderComponent,

        // Views
        HomeView,

        WhatsappPipe,

    ],
	imports: [
		BrowserModule,
		AppRoutingModule,

		// Http Module to load wordpress api
		HttpClientModule,
		ReactiveFormsModule,

		// carousel
		CarouselModule,
		BrowserAnimationsModule,

		// lightbox
		LightboxModule,

		// bairros
		NeighborhoodModule,

		// busca
		SearchModule,
		PropertyModule,

		// maps
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyBMLsr2opRI1naridY8jnBe--WljUlD6yM',
			libraries: ['places']
		}),

		// mascaras
		NgxMaskModule.forRoot(options),
		RegistrationFormModule
	],
    providers: [
		{
			provide: LOCALE_ID,
			useValue: 'pt-BR'
		},
        CookieService,
		Title
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {

}

export const GLOBAL_TITLE = 'Imobiliária Panorama';
