import { BaseFormService } from "./base-form.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigurationWpApi } from "../../../app.wp-api-config";

@Injectable({
	providedIn: "root"
})
export class ContactFormService extends BaseFormService {

	constructor(http: HttpClient, configWpApi: ConfigurationWpApi) {
		super(http, configWpApi, "custom_endpoint/contacts/general_contact");
	}
}