import {Component, Input} from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { SearchboxService } from "../../../shared/services";

@Component({
    selector: "app-search-box",
    templateUrl: './search-box.component.html'
})
export class SearchBoxComponent {

    // inputs
    @Input()
    public queryParamsInput: Array<any>;

    // flags
    public searching: boolean;
    public isAdvancedSearch: boolean;
    public searchMapOpened: boolean;
    public searchPropertyTypeOpened: boolean;
    public isLoadingPropertyTypes: boolean;
    public isLoadingPropertyLocations: boolean;
    public isPopulatedPropertyTypes: boolean;
    public isPopulatedPropertyLocations: boolean;

    // configurações do mapa
    public map: any;

    // formularios
    public propertyLocationForm: FormGroup;
    public propertyTypeForm: FormGroup;
    public searchForm: FormGroup;

    // constantes
    private readonly SEARCH_RENT = 'alugar';
    private readonly SEARCH_BUY = 'comprar';


    // variáveis carregadas de fora
    public propertyTypes: any;
    public propertyLocations: any;

    // serviços
    public routerService: Router;
    private readonly searchboxService: SearchboxService;


    constructor(routerService: Router, searchboxService: SearchboxService) {
        this.routerService = routerService;
        this.searchboxService = searchboxService;
        this.isLoadingPropertyTypes = true;
        this.searching = false;
        this.isPopulatedPropertyLocations = false;
        this.isPopulatedPropertyTypes = false;
    }

    // inicialização
    public ngOnInit(): void {

        this.isAdvancedSearch = false;
        this.searchMapOpened = false;
        this.searchPropertyTypeOpened = false;

        // seta as configurações do mapa
        this.initiateMapSettings();

        // carrega os tipos de imóvel
        this.loadPropertyTypes();

        // carrega as localizações
        this.loadPropertyLocations();

        // cria os formulários
        this.createSearchForm();


    }

    // abertura de busca avançada
    public collapseAdvancedSearch(): void {
        this.isAdvancedSearch = !this.isAdvancedSearch;
    }
    // -- abertura de busca avançada


    // retorna se a busca está setada como aluguel para deixar o botão selecionado
    public isRentSet(): boolean {
        return this.searchForm.get('searchType').value == this.SEARCH_RENT;
    }

    // abertura de modais
    public toggleLocationMap(): void {
        this.searchMapOpened = !this.searchMapOpened;
    }
    public togglePropertyType(): void {
        this.searchPropertyTypeOpened = !this.searchPropertyTypeOpened;
    }
    // -- abertura de modais


    // ações dos modais
    public performSelectLocation(): void {
        if(!this.isLoadingPropertyLocations)
            this.searchForm.patchValue({
                searchLocation: this.treatsSelectPropertyTypeAndLocation(this.propertyLocationForm, 'propertyLocationCheckbox', this.propertyLocations)
            })
    }

    public clearInput(inputName: string): void {
        this.searchForm.get(inputName).setValue(null);
    }


    /**
     * Tratamento de dados de de tipo de imóvel
     * */

    public performSelectPropertyType(): void {
        if(!this.isLoadingPropertyTypes)
            this.searchForm.patchValue({
                searchPropertyType: this.treatsSelectPropertyTypeAndLocation(this.propertyTypeForm, 'propertyTypeCheckbox', this.propertyTypes)
            })
    }
    private treatsSelectPropertyTypeAndLocation(form: FormGroup, inputName: string, comparator: Array<any>): string {
        let selectedValues = form.get(inputName).value;
        let queryParamsSelected = [];
        if(inputName == 'propertyTypeCheckbox' && !this.isPopulatedPropertyTypes && this.queryParamsInput) {
            queryParamsSelected = this.queryParamsInput['searchPropertyType'] || [];
            this.isPopulatedPropertyTypes = true;
        } else if(inputName == 'propertyLocationCheckbox' && !this.isPopulatedPropertyLocations && this.queryParamsInput) {
            queryParamsSelected = this.queryParamsInput['searchLocation'] || [];
            this.isPopulatedPropertyLocations = true;
        }
        let returnedText = '';


        // Atualiza a lista de itens selecionados e seta o texto a ser retornado Ex.: Sobrado, apartamento e casa
        comparator.forEach(function(val, index) {
            if(selectedValues[index] === true || queryParamsSelected.indexOf(val.value.toString()) != -1) {
                val.selected = true;
                selectedValues[index] = true;
                returnedText += returnedText == '' ? val.name : ', ' + val.name
            } else if(selectedValues[index] === false) {
                val.selected = false;
            }
        });

        if(inputName == 'propertyTypeCheckbox') {
            form.patchValue({propertyTypeCheckbox: selectedValues});
        } else {
            form.patchValue({propertyLocationCheckbox: selectedValues});
        }

        return returnedText.replace(/, ([^,]*)$/, ' e $1');
    }
    // -- ações dos modais

    // pega os itens selecionados do checkbox
    private getSelectedItemsFromCheckbox(key: string, form: FormGroup, inputName: string, comparator: Array<any>): Array<any> {

        key = key || 'value';

        // let selectedValues = this.propertyTypeForm.get('propertyTypeCheckbox').value;
        let selectedValues = form.get(inputName).value;
        let selectedItems = [];

        comparator.forEach(function(val, index) {
            if(selectedValues[index] == true) {
                val.selected = true;
                selectedItems.push(val[key]);
            } else {
                val.selected = false;
            }
        });

        return selectedItems;
    }


    private loadPropertyTypes(): void {

        this.propertyTypes = [];

        this.isLoadingPropertyTypes = true;

        // carregamento dos tipos de imóvel
        this.searchboxService.gerPropertyTypes<any[]>()
            .subscribe((data: any[]) => {
                    this.propertyTypes = this.treatsPropertyTypesAndLocations(data);
                    this.isLoadingPropertyTypes = false;
                    // cria o formulário de tipo de imóvel
                    this.createPropertyTypeForm();
                },
                error => () => {
                    this.isLoadingPropertyTypes = false;
                }
            );
    }

    /**
     * Tratamento de localidades para filtro
     * */

    private loadPropertyLocations(): void {

        this.propertyTypes = [];

        this.isLoadingPropertyLocations = true;

        // carregamento das localidades
        this.searchboxService.gerPropertyLocations<any[]>()
            .subscribe((data: any[]) => {
                    this.propertyLocations = this.treatsPropertyTypesAndLocations(data, true);
                    this.isLoadingPropertyLocations = false;
                    // cria o formulário de tipo de imóvel
                    this.createPropertyLocationForm();
                },
                error => () => {
                    this.isLoadingPropertyLocations = false;
                }
            );
    }


    // popula dados dos tipos de propriedades e localidades
    private treatsPropertyTypesAndLocations(data: Array<any>, isLocation: boolean = false): Array<any> {

        let returnData = [];
        let titlesToOrder = [];
        data.forEach((el) => {
            titlesToOrder.push(el.title.rendered);
        });

        // Ordena em ordem alfabética
        titlesToOrder.sort();

        titlesToOrder.forEach((titleOrdered) => {
            data.forEach((el) => {
                if(el.title.rendered == titleOrdered) {
                    let title = el.acf.city ? el.title.rendered + ' - ' + el.acf.city : el.title.rendered;
                    returnData.push({
                        name: title,
                        value: el.id
                    });
                }
            });
        });

        if(isLocation) {
            returnData.push({
                name: 'Distritos de Toledo',
                value: 'distritos'
            });
            returnData.push({
                name: 'Outras Cidades',
                value: 'outras_cidades'
            });
        }

        return returnData;
    }


        // configurações do mapa
    private initiateMapSettings(): void {
        this.map = this.map || [];

        this.map.lat = -24.7254277;
        this.map.lng = -53.7420329;
        this.map.zoom = 14;
    }


    /**
     * Criação de formulários
     * */
    private createSearchForm(): void {

        this.searchForm = new FormGroup({
            searchType: new FormControl(''),
            searchLocation: new FormControl(''),
            searchPropertyType: new FormControl(''),
            rooms: new FormControl(''),
            suites: new FormControl(''),
            bathrooms: new FormControl(''),
            garages: new FormControl(''),
            hasForniture: new FormControl(''),
            minPrice: new FormControl(''),
            maxPrice: new FormControl(''),
            minSize: new FormControl(''),
            maxSize: new FormControl(''),
            searchReference: new FormControl('')
        });

        // seta campo de tipo de busca para compra
        this.searchForm.patchValue({
            searchType: this.SEARCH_BUY
        });

        this.populateByQueryParams();
    }


    private createPropertyTypeForm(): void {
        this.propertyTypeForm = new FormGroup({
            propertyTypeCheckbox: this.createCheckboxInputs(this.propertyTypes)
        });
        if(this.queryParamsInput) {
            this.performSelectPropertyType();
        }
    }
    private createPropertyLocationForm(): void {
        this.propertyLocationForm = new FormGroup({
            propertyLocationCheckbox: this.createCheckboxInputs(this.propertyLocations)
        });
        if(this.queryParamsInput) {
            this.performSelectLocation();
        }
    }

    private createCheckboxInputs(inputs) {
        const arr = inputs.map(input => {
            return new FormControl(input.selected || false);
        });
        return new FormArray(arr);
    }

    private performSubmitSearchForm() {
        this.searching = true;

        let params = this.searchForm.getRawValue();

        params.searchPropertyType = this.getSelectedItemsFromCheckbox('value', this.propertyTypeForm, 'propertyTypeCheckbox', this.propertyTypes);
        params.searchPropertyTypeName = this.getSelectedItemsFromCheckbox('name', this.propertyTypeForm, 'propertyTypeCheckbox', this.propertyTypes);

        params.searchLocation = this.getSelectedItemsFromCheckbox('value', this.propertyLocationForm, 'propertyLocationCheckbox', this.propertyLocations);
        params.searchLocationName = this.getSelectedItemsFromCheckbox('name', this.propertyLocationForm, 'propertyLocationCheckbox', this.propertyLocations);

        this.routerService.navigate(['/busca'], {
            queryParams: params
        });
    }

    private populateByQueryParams(): void {
        if(this.queryParamsInput) {
            this.searchForm.patchValue({
                searchType: this.queryParamsInput['searchType'],
                rooms: this.queryParamsInput['rooms'],
                suites: this.queryParamsInput['suites'],
                bathrooms: this.queryParamsInput['bathrooms'],
                garages: this.queryParamsInput['garages'],
                hasForniture: this.queryParamsInput['hasForniture'],
                minPrice: this.queryParamsInput['minPrice'],
                maxPrice: this.queryParamsInput['maxPrice'],
                minSize: this.queryParamsInput['minSize'],
                maxSize: this.queryParamsInput['maxSize'],
                searchReference: this.queryParamsInput['searchReference'],
            });
        }
    }

}
