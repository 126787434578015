import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PropertyService } from "../../shared/services";

@Injectable({
	providedIn: "root"
})
export class RedirectGuardService implements CanActivate {

	public router: Router;

	private propertyService: PropertyService;

	constructor (router: Router, propertyService: PropertyService) {
		this.router = router;
		this.propertyService = propertyService;
	}

	public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

		const queryParams: {imovel?: string, finalidade?: string} = route.queryParams;
		const property = await this.propertyService.findOneByReference(Number(queryParams.imovel)).toPromise();

		return this.router.navigate(['imovel/'+ this.propertyService.getPropertyUrl(property[0])]);
	}
}