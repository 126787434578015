import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NewsletterFormService, VacancyPropertyFormService } from "../../services";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: 'newsletter-view',
    templateUrl: './newsletter.view.html'
})
export class NewsletterView implements OnInit {

    //#region Public Attributes
    /**
     * Flag que indica se está em processo de submissão do formulário
     * @default false
     */
    public isSubmitting: boolean;
    public isSubmitted: boolean;
    /**
     * Erro ocorrido na submissão do formulário.
     * @default null
     */
    public error: string;
    /**
     * Formulário de desocupação de imóvel.
     */
    public newsletterForm: FormGroup;
    //#endregion

    //#region Private Attributes
    /**
     * @injected
     */
    private readonly newsletterFormService: NewsletterFormService;
    //#endregion

    //#region Constructor
    constructor(newsletterFormService: NewsletterFormService) {

        // Injetados
        this.newsletterFormService = newsletterFormService;

        /// Públicos
        this.isSubmitting = false;
        this.isSubmitted = false;
        this.error = null;
    }
    //#endregion

    //#region Lifecycle Hooks
    /**
     * @inheritDoc
     */
    public ngOnInit(): void {
        this.createNewsletterForm();
    }
    //#endregion

    //#region Public Methods
    public performSubmitForm(): void {

        this.error = null;
        this.isSubmitting = true;
        const formData: Object = this.newsletterForm.getRawValue();
        this.newsletterFormService.submit(formData).subscribe(
            () => {
                this.isSubmitting = false;
                this.isSubmitted = true;
            },
            (error: HttpErrorResponse) => {
                this.error = error.message;
                this.isSubmitting = false;
                this.isSubmitted = true;
            },
        );
    }
    //#endregion

    //#region Private Methods
    /**
     * Criação de formulários
     * */
    private createNewsletterForm(): void {

        this.newsletterForm = new FormGroup({
            email: new FormControl('', [
                Validators.required
            ])
        });
    }
    //#endregion
}