import { Component, Input } from "@angular/core";
import {PropertyService} from "../../../shared/services";
import {OwlOptions} from "ngx-owl-carousel-o";

@Component({
    selector: 'app-property-list',
    templateUrl: './property-list.view.html'
})

export class PropertyListView {

    @Input()
    public properties: Array<any>;

    @Input()
    public isCaroussel: boolean;

    private readonly propertyService: PropertyService;

    constructor(propertyService: PropertyService) {
        this.propertyService = propertyService;
    }

    getUrl(property) {
        return property.acf.photos[0].sizes ? property.acf.photos[0].sizes.medium_large : property.acf.photos[0].url;
    }

    propertiesCustomOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navText: ['prev', 'next'],
        responsive: {
            0: {
                items: 1.15
            },
            400: {
                items: 1.15
            },
            740: {
                items: 3
            },
            940: {
                items: 3
            }
        },
        nav: true
    }

}