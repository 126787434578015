import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SearchService } from "../../../shared/services";
import { PropertyService } from "../../../../property/shared/services";
import {CookieService} from "ngx-cookie-service";
import {GLOBAL_TITLE} from "../../../../app.module";
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'search-list-view',
    templateUrl: './search-list.view.html'
})
export class SearchListView {

    // carroussel de imagens

    // flags
    public isLoading: boolean;
    public isOpenFilterForm: boolean;

    // parametros de busca
    public queryParams: Array<any>;
    public searchParamsReadable: string;

    // imóveis da busca
    public properties: Array<string>;
    public lookalikeProperties: Array<string>;

    // serviços
    private readonly activatedRoute: ActivatedRoute;
    private readonly searchService: SearchService;
    private readonly propertyService: PropertyService;
    private readonly cookieService: CookieService;
    private readonly titleService: Title;


    constructor(activatedRoute: ActivatedRoute,
                searchService: SearchService,
                propertyService: PropertyService,
                cookieService: CookieService,
                titleService: Title) {
        this.activatedRoute = activatedRoute;
        this.searchService = searchService;
        this.propertyService = propertyService;
        this.cookieService = cookieService;
        this.titleService = titleService;

        this.isLoading = true;
        this.isOpenFilterForm = false;

        this.titleService.setTitle(GLOBAL_TITLE + ' - Busca');
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.activatedRoute.queryParams.subscribe((queryParams: Array<any>) => {

            this.queryParams = queryParams;

            this.searchService.generalSearch<any[]>(queryParams)
                .subscribe((data: any[]) => {
                   this.isLoading = false;
                   this.properties = data['mainSearch'];
                   this.putOnCookie(this.properties);
                   this.lookalikeProperties = data['lookalikeSearch'] || [];
                   
                }, error => () => {
                    this.isLoading = false;
                }
            );
        });

    }

    public getSearchReadable(params: Array<any>, sensitive: boolean): string {
        let searchReadable: string;

        searchReadable = sensitive ? 'Imóvel para ' : 'imóvel para ';
        searchReadable += params['searchType'] == "alugar" ? "alugar" : "comprar";
        searchReadable += params['searchPropertyTypeName'] ? " ("+this.getSearchPropertyTypeNameFormatted()+")" : "";
        searchReadable += params['searchLocation'] && params['searchLocation'].length ? " no(s) bairro(s) " + this.getSearchPropertyLocationNameFormatted() : "";
        searchReadable += params['rooms'] ? ", quartos: " + params['rooms'] : "";
        searchReadable += params['suites'] ? ", suítes: " + params['suites'] : "";
        searchReadable += params['bathrooms'] ? ", banheiros: " + params['bathrooms'] : "";
        searchReadable += params['garages'] ? ", garagens: " + params['garages'] : "";

        return searchReadable;
    }

    private getSearchPropertyTypeNameFormatted(): string {
        return this.formatTitle(this.queryParams['searchPropertyTypeName']);
    }

    private getSearchPropertyLocationNameFormatted(): string {
        return this.formatTitle(this.queryParams['searchLocationName']);
    }

    private toggleFilterForm(): void {
        this.isOpenFilterForm = !this.isOpenFilterForm;
    }

    private formatTitle(params: any): string {

        let returnedText = '';
        let val;
        if(!Array.isArray(params))
            return params;

        for (let i = 0; i < params.length; i++) {
            val = params[i];
            returnedText += returnedText == '' ? val : ', ' + val;
        }
        return returnedText.replace(/, ([^,]*)$/, ' e $1');
    }

    private putOnCookie(properties: Array<any>) {
        let idsAlreadyIn = this.cookieService.get('everyPropertyLookedAt').split(',');
        let ids = '';

        if(idsAlreadyIn.length > 30) {
            for (let i = idsAlreadyIn.length; i > 30; i--) {
                ids += idsAlreadyIn + ',';
            }
        } else {
            ids += idsAlreadyIn.join(',') + ',';
        }

        if(properties.length) {
            properties.forEach((property) => {
                ids += property.ID + ',';
            });
            this.cookieService.set('everyPropertyLookedAt', ids);
        }
    }

}