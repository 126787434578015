import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigurationWpApi } from "../../../app.wp-api-config";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})

export class FooterService {

    private actionUrl: string;
    private wpEndpoint: string;
    private wpApiOptEndpoint: string;


    private readonly MENU_FOOTER_INSTITUCIONAL = 'footer_menu_institucional';
    private readonly MENU_FOOTER_PARAVOCE = 'footer_menu_paravoce';


    constructor(private http: HttpClient, private configWpApi: ConfigurationWpApi) {
        this.wpEndpoint = 'custom_endpoint/menu/';
        this.actionUrl = configWpApi.wpApiUrl + this.wpEndpoint;
        this.wpApiOptEndpoint = configWpApi.wpApiOptUrl;
    }

    public getFooterMenuInstitucional<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrl + this.MENU_FOOTER_INSTITUCIONAL)
    }

    public getFooterMenuParaVoce<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrl + this.MENU_FOOTER_PARAVOCE)
    }

    public getOption<T>(optionName): Observable<T> {
        return this.http.get<T>(this.wpApiOptEndpoint + optionName);
    }

}
