import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: 'person-registration-form-list-view',
    templateUrl: './person-registration-form-list.view.html'
})
export class PersonRegistrationFormListView {

    // flags
    public isLoading: boolean;


    // serviços
    private readonly activatedRoute: ActivatedRoute;



    constructor(activatedRoute: ActivatedRoute) {
        this.activatedRoute = activatedRoute;
    }

    public ngOnInit(): void {
    }


}