import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
declare let ga: Function;
declare let fbq: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  public title = "panorama-angular";

  constructor(public router: Router) {
    // subscribe to router events and send team views to Google Analytics
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ga("set", "page", event.urlAfterRedirects);
        ga("send", "pageview");
        fbq("track", "PageView");
      }
    });
  }
}
