import { Component } from '@angular/core';
import {SlidersService} from "./services";
import {OwlOptions} from "ngx-owl-carousel-o";

@Component({
    selector: "app-slider",
    templateUrl: "./slider.component.html"
})
export class SliderComponent {

    public isLoading: boolean;

    public slider: Array<any>;

    // Declaração de serviços
    private readonly slidersService: SlidersService;

    constructor(slidersSerivce: SlidersService) {
        this.slidersService = slidersSerivce;
    }


    public ngOnInit(): void {

        this.isLoading = true;

        this.slidersService.getSliders<any[]>()
            .subscribe((data: any[]) => {
                    this.slider = data;
                    this.isLoading = false;
                },
                error => () => {
                    this.isLoading = false;
                }
            );
    }


    sliderCustomOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        navText: ['prev', 'next'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 1
            }
        },
        nav: true
    }

}
