import { Component } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FooterService } from "./services";
import { MenuBarService } from "../menu-bar/services";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html"
})

export class FooterComponent {

    // variáveis de carregamento
    public isLoadingMenu: any;

    // variáveis
    public mainAddress: any;
    public phoneNumber: any[];
    public saleWppNumber: any[];
    public rentWppNumber: any[];
    public menuFooterInstitucional: Array<string>;
    public menuFooterParaVoce: Array<string>;

    // flags
    public wppContactOpen: boolean;

    // formulário newsletter
    public newsletterForm: any;

    // serviços de requisição
    private readonly footerService: FooterService;

    constructor(footerService: FooterService) {
        this.footerService = footerService;
    }


    public ngOnInit(): void {

        let loadingVariables = {
            menuInstitucional: true,
            menuParaVoce: true
        };

        this.isLoadingMenu = loadingVariables;

        // cria formulário de newsletter
        this.createNewsletterForm();


        // carregamento do menu institucional
        this.footerService.getFooterMenuInstitucional<any[]>()
            .subscribe((data: any[]) => {
                    this.menuFooterInstitucional = this.treatsMenu(data);
                    this.isLoadingMenu.menuInstitucional = false;
                },
                error => () => {
                    this.isLoadingMenu.menuInstitucional = false;
                }
            );

        this.footerService.getFooterMenuParaVoce<any[]>()
            .subscribe((data: any[]) => {
                    this.menuFooterParaVoce = this.treatsMenu(data);
                    this.isLoadingMenu.menuParaVoce = false;
                },
                error => () => {
                    this.isLoadingMenu.menuParaVoce = false;
                }
            );

        // carrega o endereço e telefones
        this.footerService.getOption<any[]>('main_address').subscribe((data: any[]) => { this.mainAddress = data; });
        this.footerService.getOption<any[]>('phone_textbox').subscribe((data: any[]) => { this.phoneNumber = data; });
        this.footerService.getOption<any[]>('sale_wpp_textbox').subscribe((data: any[]) => { this.saleWppNumber = data; });
        this.footerService.getOption<any[]>('rent_wpp_textbox').subscribe((data: any[]) => { this.rentWppNumber = data; });

    }

    // função de criação do formulário de newsletter
    private createNewsletterForm(): void {
        this.newsletterForm = new FormGroup({
            searchType: new FormControl('')
        });
    }

    // tratamento do menu
    private treatsMenu(req) {

        let menu = [];
        let isInternalRouter = false;

        // tratamento de itens do menu
        req.forEach(menuItem => {
            if(menuItem.menu_item_parent == 0) {

                isInternalRouter = menuItem.url.includes('#./');

                menu.push({
                    id: menuItem.ID,
                    title: menuItem.title,
                    url: isInternalRouter ? menuItem.url.replace('#', '') : menuItem.url,
                    useRouter: isInternalRouter,
                    submenu: null
                });
            }
        });


        // tratamento de itens do submenu
        menu.forEach(menuItem => {
            req.forEach(submenuItem => {
                if(submenuItem.menu_item_parent == menuItem.id) {

                    isInternalRouter = submenuItem.url.includes('#./');

                    menuItem.submenu = menuItem.submenu == null ? [] : menuItem.submenu;

                    menuItem.submenu.push({
                        id: submenuItem.ID,
                        title: submenuItem.title,
                        url: isInternalRouter ? submenuItem.url.replace('#', '') : submenuItem.url,
                        useRouter: isInternalRouter
                    });
                }
            });
        });

        return menu;
    }

    private toggleWhatsAppOpen() {
        this.wppContactOpen = !this.wppContactOpen;
    }
}
