import { Component } from '@angular/core';
import { MenuBarService } from "./services";
import {query} from "@angular/animations";

@Component({
    selector: "app-menu-bar",
    templateUrl: "./menu-bar.component.html"
})
export class MenuBarComponent {

    public isLoading: boolean;
    public isLoadingDesktop: boolean;

    public isOpen: boolean;

    public menuHeader: Array<string>;
    public menuHeaderDesktop: Array<string>;

    public phoneNumber: any[];
    public saleWppNumber: any[];
    public rentWppNumber: any[];



    // Declaração de serviços
    private readonly menubarService: MenuBarService;

    constructor(menubarSerivce: MenuBarService) {
        this.menubarService = menubarSerivce;
    }


    public closeMenu(): void {
        this.isOpen = false;
    }

    public openMenu(): void {
        this.isOpen = true;
    }


    public ngOnInit(): void {

        this.isLoading = true;
        this.isLoadingDesktop = true;

        this.isOpen = false;

        this.menubarService.getHeaderMenu<any[]>()
            .subscribe((data: any[]) => {
                    this.menuHeader = this.treatsMenu(data);
                    this.isLoading = false;
                },
                error => () => {
                    this.isLoading = false;
                }
            );
        this.menubarService.getHeaderMenuDesktop<any[]>()
            .subscribe((data: any[]) => {
                    this.menuHeaderDesktop = this.treatsMenu(data);
                    this.isLoadingDesktop = false;
                },
                error => () => {
                    this.isLoadingDesktop = false;
                }
            );

        this.menubarService.getPhoneNumber<any[]>('phone_textbox').subscribe((data: any[]) => { this.phoneNumber = data; });
        this.menubarService.getPhoneNumber<any[]>('sale_wpp_textbox').subscribe((data: any[]) => { this.saleWppNumber = data; });
        this.menubarService.getPhoneNumber<any[]>('rent_wpp_textbox').subscribe((data: any[]) => { this.rentWppNumber = data; });
    }


    private treatsMenu(req) {

        if(!req.length) return;
        let menu = [];
        let isInternalRouter = false;
        let queryParams = '';

        // tratamento de itens do menu
        req.forEach(menuItem => {
            if(menuItem.menu_item_parent == 0) {

                if(menuItem.url.indexOf('?') != -1) {
                    queryParams = menuItem.url;
                    menuItem.url = menuItem.url.split('?')[0];
                }

                isInternalRouter = menuItem.url.includes('#./');
                menu.push({
                    id: menuItem.ID,
                    title: menuItem.title,
                    url: isInternalRouter ? menuItem.url.replace('#', '') : menuItem.url,
                    target: isInternalRouter ? 'self' : 'blank',
                    queryParams: this.getQueryParams(queryParams),
                    useRouter: isInternalRouter,
                    submenu: null
                });
                queryParams = '';
            }
        });


        // tratamento de itens do submenu
        menu.forEach(menuItem => {
            req.forEach(submenuItem => {
                if(submenuItem.menu_item_parent == menuItem.id) {

                    isInternalRouter = submenuItem.url.includes('#./');

                    menuItem.submenu = menuItem.submenu == null ? [] : menuItem.submenu;

                    if(submenuItem.url.indexOf('?') != -1) {
                        queryParams = submenuItem.url;
                        submenuItem.url = submenuItem.url.split('?')[0];
                    }

                    menuItem.submenu.push({
                        id: submenuItem.ID,
                        title: submenuItem.title,
                        url: isInternalRouter ? submenuItem.url.replace('#', '') : submenuItem.url,
                        target: isInternalRouter ? 'self' : 'blank',
                        queryParams: this.getQueryParams(queryParams),
                        useRouter: isInternalRouter
                    });
                    queryParams = '';
                }
            });
        });

        return menu;
    }

    private getQueryParams(link): Array<any> {
        let queryParams = [];

        link = link.split('?');
        link = link.length > 1 ? link[1] : link[0];
        link = link.split('&');
        link.forEach((param) => {
            let paramSplited = param.split('=');
            queryParams[paramSplited[0]] = paramSplited[1];
        });

        return queryParams;
    }

}
