import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";

import {
    PartySaloonReservationView,
    AnnouncePropertyView,
    RegistrationFormListView,
    VacancyPropertyView,
    PersonRegistrationFormListView,
    OwnerPropertyView,
    PhysicalPersonView,
    JuridicalPersonView
} from "./views";

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: "",
                component: RegistrationFormListView
            },
            {
                path: "anuncie-seu-imovel",
                component: AnnouncePropertyView
            },
            {
                path: "desocupar-imovel",
                component: VacancyPropertyView
            },
            {
                path: "reserva-salao-festa",
                component: PartySaloonReservationView
            },
            {
                path: "cadastro-pessoal",
                component: PersonRegistrationFormListView
            },
            {
                path: "cadastro-pessoal/proprietario",
                component: OwnerPropertyView
            },
            {
                path: "cadastro-pessoal/pessoa-fisica",
                component: PhysicalPersonView
            },
            {
                path: "cadastro-pessoal/pessoa-juridica",
                component: JuridicalPersonView
            }
        ])
    ],
    exports: [ RouterModule ]
})
export class RegistrationFormRoutingModule {}