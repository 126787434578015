import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AnnouncePropertyFormService } from "../../services";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "announce-property-view",
  templateUrl: "./announce-property.view.html",
})
export class AnnouncePropertyView {
  //#region Public Attributes
  /**
   * Flag que indica se está em processo de submissão do formulário
   * @default false
   */
  public isSubmitting: boolean;
  public isSubmitted: boolean;
  /**
   * Erro ocorrido na submissão do formulário.
   * @default null
   */
  public error: string;
  /**
   * Formulário de cadastro de proprietário.
   */
  public announcePropertyForm: FormGroup;
  //#endregion

  //#region Private Attributes
  /**
   * @injected
   */
  private readonly activatedRoute: ActivatedRoute;
  /**
   * @injected
   */
  private readonly announcePropertyFormService: AnnouncePropertyFormService;
  public images: string[];
  public imagesOwnerDocuments: string[];
  public imagesPropertyDocuments: string[];

  //#endregion

  //#region Constructor
  constructor(
    activatedRoute: ActivatedRoute,
    announcePropertyFormService: AnnouncePropertyFormService,
    private http: HttpClient
  ) {
    // Injetados
    this.activatedRoute = activatedRoute;
    this.announcePropertyFormService = announcePropertyFormService;

    // Públicos
    this.isSubmitting = false;
    this.isSubmitted = false;
    this.error = null;
  }
  //#endregion

  //#region Lifecycle Hooks
  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.createAnnouncePropertyForm();
  }
  //#endregion

  //#region Public Methods
  processFile(imageInput: any, arrayToPush: string) {
    this[arrayToPush] = [];
    const files = imageInput.files;

    for (let file of files) {
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        this[arrayToPush].push((event.target as any).result);
      });
      reader.readAsDataURL(file);
    }
  }

  public performSubmitForm(): void {
    this.error = null;
    this.isSubmitting = true;
    let formData: Object = this.announcePropertyForm.getRawValue();
    formData = {
      ...formData,
      images: this.images,
      ownerDocuments: this.imagesOwnerDocuments,
      documents: this.imagesPropertyDocuments,
    };

    this.announcePropertyFormService.submit(formData).subscribe(
      () => {
        this.isSubmitting = false;
        this.isSubmitted = true;
      },
      (error: HttpErrorResponse) => {
        this.error = error.message;
        this.isSubmitting = false;
        this.isSubmitted = true;
      }
    );
  }
  //#endregion

  //#region Private Methods
  /**
   * Criação de formulários
   * */
  private createAnnouncePropertyForm(): void {
    this.announcePropertyForm = new FormGroup({
      announceType: new FormControl("1"),
      ownerName: new FormControl("Teste wing", [Validators.required]),
      ownerEmail: new FormControl("Teste wing", [Validators.required]),
      ownerCellphone: new FormControl("Teste wing", [Validators.required]),
      ownerDocuments: new FormControl("Teste wing", [Validators.required]),
      propertyCep: new FormControl("Teste wing", [Validators.required]),
      propertyUf: new FormControl("Teste wing", [Validators.required]),
      propertyCity: new FormControl("Teste wing", [Validators.required]),
      propertyNeighborhood: new FormControl("", [Validators.required]),
      propertyAddress: new FormControl("", [Validators.required]),
      propertyNumber: new FormControl("", [Validators.required]),
      propertyValue: new FormControl("", [Validators.required]),
      propertyIPTU: new FormControl("", [Validators.required]),
      propertyInternalArea: new FormControl("", [Validators.required]),
      propertyFloor: new FormControl("", [Validators.required]),
      propertyFinishing: new FormControl("", [Validators.required]),
      propertyPosition: new FormControl("", [Validators.required]),
      propertyBedrooms: new FormControl("", [Validators.required]),
      propertySuites: new FormControl("", [Validators.required]),
      propertyRooms: new FormControl("", [Validators.required]),
      propertyBalconies: new FormControl("", [Validators.required]),
      propertyParkingSpaces: new FormControl("", [Validators.required]),
      propertyRegistration: new FormControl("", [Validators.required]),
      propertyRealEstateRegistryOffice: new FormControl("", [
        Validators.required,
      ]),
      propertyMunicipalRegistry: new FormControl("", [Validators.required]),
      propertyDocuments: new FormControl("", [Validators.required]),
      propertyCompliment: new FormControl(""),
      propertyReferencePoint: new FormControl(""),
      propertyBuildingName: new FormControl(""),
      propertyCondominio: new FormControl(""),
      propertyExternalArea: new FormControl(""),
      propertyBatch: new FormControl(""),
      propertyLeisureGym: new FormControl(""),
      propertyLeisureGamesRoom: new FormControl(""),
      propertyLeisureHomeCinema: new FormControl(""),
      propertyLeisureGrill: new FormControl(""),
      propertyLeisureSportsCourt: new FormControl(""),
      propertyLeisurePool: new FormControl(""),
      propertyLeisureMassageRoom: new FormControl(""),
      propertyLeisurePlayground: new FormControl(""),
      propertyLeisureSauna: new FormControl(""),
      propertyLeisurePartyRoom: new FormControl(""),
      propertyLeisureWhirlpool: new FormControl(""),
      propertyLeisureWifi: new FormControl(""),
      propertyFeaturesIndividualWater: new FormControl(""),
      propertyFeaturesIntercom: new FormControl(""),
      propertyFeaturesSolarHeating: new FormControl(""),
      propertyFeaturesConcierge: new FormControl(""),
      propertyFeaturesElectricHeating: new FormControl(""),
      propertyFeaturesElectricFence: new FormControl(""),
      propertyFeaturesGasHeating: new FormControl(""),
      propertyFeaturesGarden: new FormControl(""),
      propertyFeaturesPipedgas: new FormControl(""),
      propertyFeaturesSecurityCameras: new FormControl(""),
      propertyFeaturesElectronicGate: new FormControl(""),
      propertyFeaturesLaundry: new FormControl(""),
      propertyFeaturesAlarm: new FormControl(""),
      propertyData: new FormControl(""),
      agree: new FormControl(false, [Validators.pattern("true")]),
      agreeValue: new FormControl(false, [Validators.pattern("true")]),
      agreeTerms: new FormControl(false, [Validators.pattern("true")]),
    });
  }

  /*valida se possui imagens no input documento*/
  onImageChange(input: any): void {
    if (input.files.length > 0) {
      this.announcePropertyForm.get("ownerDocuments").setErrors(null);
    } else {
      this.announcePropertyForm
        .get("ownerDocuments")
        .setErrors({ required: true });
    }
  }

  onImageChange1(input: any): void {
    if (input.files.length > 0) {
      this.announcePropertyForm.get("propertyDocuments").setErrors(null);
    } else {
      this.announcePropertyForm
        .get("propertyDocuments")
        .setErrors({ required: true });
    }
  }

  getAddressFromCep(cep: string) {
    this.http
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .subscribe((address: any) => {
        this.announcePropertyForm.patchValue({
          propertyUf: address.uf,
          propertyCity: address.localidade,
          propertyNeighborhood: address.bairro,
          propertyAddress: address.logradouro,
        });
      });
  }

  //#endregion
}
