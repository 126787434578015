import {Injectable} from "@angular/core";
import { Observable } from "rxjs";
import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";

// Configuração da API do WP
import { ConfigurationWpApi } from "../../../app.wp-api-config";


@Injectable({
    providedIn: "root"
})

export class PropertyService {

    private actionUrl: string;
    private wpEndPoint: string;
    private customActionUrl: string;
    private customWpEndPoint: string;

    constructor(private http: HttpClient, private configWpApi: ConfigurationWpApi) {
        this.wpEndPoint = 'wp/v2/properties/';
        this.actionUrl = configWpApi.wpApiUrl + this.wpEndPoint;

        this.customWpEndPoint = 'custom_endpoint/';
        this.customActionUrl = configWpApi.wpApiUrl + this.customWpEndPoint;
    }

    public getProperty<T>(id: number): Observable<T> {
        return this.findOneById(id);
    }

    // busca um imóvel pelo ID
    public findOneById<T>(id: number): Observable<T> {
        return this.http.get<T>(this.actionUrl + id);
    }

    public findOneByReference<T>(reference: number): Observable<T> {
        return this.http.get<T>(this.customActionUrl + 'get_property_by_reference/' + reference);
    }

    public findManyById<T>(ids: Array<number>): Observable<T> {
        let first = true;
        let params: string = '';
        for (let id of ids) {
            params += first ? '?include[]=' + id : '&include[]=' + id;
            first = false;
        }
        return this.http.get<T>(this.actionUrl + params);
    }

    /**
     * @author Jhone Turra
     *
     * Aqui o negócio fica baum pia
     * A função abaixo serve para buscar imóveis parecidos com uma lista de dados estruturado
     * contendo o id dos imóveis que foram exibidos na tela do consagrado que ta acessando o site
     *
     * */

    public searchForSomethingLikeThis<T>(dataIds: Array<any>): Observable<T> {

        dataIds = dataIds ? dataIds : [];
        let paramsUrl = "";

        if(dataIds.length)
            dataIds.forEach((id) => {
                paramsUrl += id + ",";
            });
        else
            paramsUrl = "random";

        return this.http.get<T>(this.customActionUrl + 'lookalike/' + paramsUrl);
    }

    // tratamento de dados do título
    public treatsTitle(title: string, complete: boolean = false): string {

        let treatedTitle = title.split(',');

        if(!complete) {
            // Sobrado para alugar no Centro de Toledo
            // Apartamento para aluguel, 2 quartos, 1 vaga, Jardim Coopagro &#8211; Toledo/PR
            // pega o primeiro e o último item do título
            treatedTitle = [treatedTitle[0], treatedTitle[treatedTitle.length - 1]];
        }

        for (let i = 0; i < treatedTitle.length; i++) {
            treatedTitle[i] = treatedTitle[i].replace('aluguel', 'alugar');
            treatedTitle[i] = treatedTitle[i].replace('vender', 'venda');
            treatedTitle[i] = treatedTitle[i].replace('&#8211;', 'de');
            treatedTitle[i] = treatedTitle[i].replace('/PR', '');
        }

        return treatedTitle.join(',');
    }

    public getPropertyUrl(property: any): string {
        let url = property.ID != undefined ? property.ID + '-' + property.post_name : property.id + '-' + property.slug;
        url += "--" + property.acf.code;
        return url;
    }
}

@Injectable({
    providedIn: "root"
})
export class CustomInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.headers.has('Content-Type')) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });


        return next.handle(req);
    }
}