import { Component } from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {split} from "ts-node";
import {Title} from "@angular/platform-browser";
import {GLOBAL_TITLE} from "../../app.module";

@Component({
    selector: "app-home-view",
    templateUrl: "./home.view.html"
})
export class HomeView {

    public recommendedPropertiesByCookie: Array<any>;

    private readonly cookieService: CookieService;
    private readonly titleService: Title;

    constructor(cookieService: CookieService, titleService: Title) {
        this.titleService = titleService;
        this.cookieService = cookieService;
    }

    public ngOnInit(): void {
        this.titleService.setTitle(GLOBAL_TITLE);
        this.recommendedPropertiesByCookie = this.cookieService.get('everyPropertyLookedAt').split(',');
    }

}