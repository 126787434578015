import {Injectable} from "@angular/core";
import { Observable } from "rxjs";
import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";

// Configuração da API do WP
import { ConfigurationWpApi } from "../../../app.wp-api-config";


@Injectable({
    providedIn: "root"
})

export class NeighborhoodService {

    private actionUrl: string;
    private actionUrlCustom: string;
    private wpEndPoint: string;
    private wpCustomEndpoin: string;

    constructor(private http: HttpClient, private configWpApi: ConfigurationWpApi) {
        this.wpEndPoint = 'wp/v2/neighborhood/';
        this.actionUrl = configWpApi.wpApiUrl + this.wpEndPoint;

        this.wpCustomEndpoin = 'custom_endpoint/get_multiple_neighborhoods/';
        this.actionUrlCustom = configWpApi.wpApiUrl + this.wpCustomEndpoin;
    }

    public getNeighborhood<T>(id: number): Observable<T> {
        return this.findOneById(id);
    }

    // busca muitos imóveis
    public getNeighborhoods<T>(ids: Array<number>): Observable<T> {
        if(ids.length)
            return this.http.get<T>(this.actionUrlCustom + ids.join(','));
        else
            return this.http.get<T>(this.actionUrlCustom + 'all');
    }

    // busca um imóvel pelo ID
    public findOneById<T>(id: number): Observable<T> {
        return this.http.get<T>(this.actionUrl + id);
    }

}

@Injectable({
    providedIn: "root"
})
export class CustomInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.headers.has('Content-Type')) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });


        return next.handle(req);
    }
}