import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root"
})
export class ConfigurationWpApi {

    public readonly server = 'https://backofficeimobpanorama.com.br/';
    public readonly apiUrl = 'wp-json/';
    public readonly wpApiUrl = this.server + this.apiUrl;
    public readonly wpApiOptUrl = this.wpApiUrl + 'custom_endpoint/option/';

}
